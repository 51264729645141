export default [
  {
    title: 'Orders',
    route: 'orders',
    icon: 'HomeIcon',
  },
  // {
  //   title: 'Second Page',
  //   route: 'second-page',
  //   icon: 'FileIcon',
  // },
]
